import { Header as GlobalHeader } from '@pocketoutdoormedia/global-header';
import { getCookies } from 'cookies-next';
import { getUserState } from '@pocketoutdoormedia/auth-state-service';
import HeaderIcons from 'components/02-molecules/HeaderIcons';
import HeaderLogo from 'components/01-atoms/HeaderLogo';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { headerNavigationConfig } from './helper';

const Header = () => {
  const cookies = getCookies();
  const userState = getUserState(cookies);
  const { accountsEmailVerification } = useFlags();

  return (
    <GlobalHeader
      logo={<HeaderLogo />}
      navigation={headerNavigationConfig}
      theme="dark"
      userData={
        userState.isAuthenticated
          ? {
              ...userState.userData,
              isPremium: userState.userData.isSubscriber,
              // if 'accountsEmailVerification' flag is off, users email is treated as verified
              isEmailVerified:
                userState.userData.emailVerified || !accountsEmailVerification
            }
          : null
      }
    >
      <HeaderIcons />
    </GlobalHeader>
  );
};

export default Header;
