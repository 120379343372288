import type {
  ToastConfig,
  ToastContainerElement
} from '@pocketoutdoormedia/toast/dist/src/types';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useEffect } from 'react';

export const showToast = async (config: ToastConfig) => {
  const { showToast: show } = await import('@pocketoutdoormedia/toast');

  show(config);
};

export const showEmailVerificationWarning = async () => {
  // We must first import this package so that the email-verify-warning-notification package
  // works properly because it depends on the main toast package
  await import('@pocketoutdoormedia/toast');
  const { showEmailVerificationWarning: show } = await import(
    '@pocketoutdoormedia/email-verify-warning-notification'
  );

  show();
};

export const ToastContainer = () => {
  const { accountsEmailVerification } = useFlags();

  useEffect(() => {
    if (accountsEmailVerification) {
      showEmailVerificationWarning();
    }
  }, [accountsEmailVerification]);

  return (
    <div className="relative">
      <out-toast-container />
    </div>
  );
};

declare global {
  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace JSX {
    interface IntrinsicElements {
      'out-toast-container': ToastContainerElement;
    }
  }
}
