import { SCOUT_APP_URL } from 'lib/constants';
import { useAnalyticsContext } from 'components/04-templates/AnalyticsProvider';
import { useCallback } from 'react';

const ScoutLink = () => {
  const { elementClicked } = useAnalyticsContext();

  const handleOnClick = useCallback(() => {
    elementClicked({
      name: 'Scout icon',
      type: 'link'
    });
  }, [elementClicked]);

  return (
    <a
      href={SCOUT_APP_URL}
      aria-label="Scout App"
      className="!flex h-10 w-10 items-center justify-center rounded-full hover:bg-white/20 focus-visible:bg-white/20 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-focus"
      onClick={handleOnClick}
    >
      <svg
        width="44"
        height="44"
        viewBox="0 0 44 44"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g>
          <path
            id="scout-star"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M31 15.5C31 14.3954 31.8954 13.5 33 13.5C31.8954 13.5 31 12.6046 31 11.5C31 12.6046 30.1046 13.5 29 13.5C30.1046 13.5 31 14.3954 31 15.5Z"
            fill="#FFD100"
            stroke="#FFD100"
            strokeWidth="0.999583"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M24.9474 13.5H16C14.343 13.5 13 14.843 13 16.5V24.5C13 26.157 14.343 27.5 16 27.5H17.5V30.5L22 27.5H28C29.657 27.5 31 26.157 31 24.5V19.5"
            stroke="white"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
      </svg>
    </a>
  );
};

export default ScoutLink;
