import type { NavigationCallbacksRecord } from '@pocketoutdoormedia/global-header/dist/types';
import Router from 'next/router';
import { getSignInURL, getSignOutURL } from '@pocketoutdoormedia/global-header';
import {
  ACCOUNTS_OUTSIDE_ONLINE_URLS,
  ANALYTICS_DOMAIN,
  OUTSIDE_INC_URLS
} from 'lib/constants';
import { routes } from 'lib/routes';

const getOutsidePlusUrl = () => {
  const oPlusSearchParams = new URLSearchParams({
    analytics_domain: ANALYTICS_DOMAIN,
    redirectUrl: window.location.href
  });

  return `${ACCOUNTS_OUTSIDE_ONLINE_URLS.OUTSIDE_PLUS}?${oPlusSearchParams}`;
};

export const getSignUpUrl = () => {
  const signUpSearchParams = new URLSearchParams({
    analytics_domain: ANALYTICS_DOMAIN,
    next: window.location.href
  });

  return `${ACCOUNTS_OUTSIDE_ONLINE_URLS.SIGN_UP}?${signUpSearchParams}`;
};

export const headerNavigationConfig: NavigationCallbacksRecord = {
  BOOKMARKS: () => Router.push(routes.bookmarks()),
  HELP: () => Router.push(OUTSIDE_INC_URLS.HELP),
  MY_BENEFITS: () => Router.push(routes.myBenefits()),
  OPLUS_SITE: () => Router.push(getOutsidePlusUrl()),
  PROFILE: () => Router.push(ACCOUNTS_OUTSIDE_ONLINE_URLS.PROFILE),
  REGISTER: () => Router.push(getSignUpUrl()),
  SETTINGS: () => Router.push(ACCOUNTS_OUTSIDE_ONLINE_URLS.SETTINGS),
  SIGN_IN: () => Router.push(getSignInURL()),
  SIGN_OUT: () => Router.push(getSignOutURL()),
  SUBSCRIPTION: () => Router.push(ACCOUNTS_OUTSIDE_ONLINE_URLS.SUBSCRIPTION)
};
